<template>
    <nav
        aria-label="Sidebar"
        class="group fixed bottom-10 left-0 top-0 z-30 hidden w-24 border-r border-opacity-20 bg-opacity-10 transition-all duration-200 ease-out hover:w-[325px] md:block md:flex-shrink-0 md:overflow-x-visible"
        @mouseenter="setExpanded(true)"
        @mouseleave="setExpanded(false)"
    >
        <div class="absolute inset-0 md:bg-white" />

        <div class="absolute right-0 top-20 h-5 w-5 translate-x-1/2 rounded-full bg-brand-blue p-1 text-white">
            <ChevronRightIcon :class="{ 'rotate-180': isExpanded }" class="h-auto w-full" />
        </div>

        <div class="relative flex h-full w-24 flex-col gap-3 overflow-x-visible pb-3">
            <LeftNavLogo :is-expanded="isExpanded" />

            <LeftNavItem
                :item="{
                    title: 'Home',
                    to: '/home',
                }"
                :is-expanded="isExpanded"
            >
                <HomeIcon />
            </LeftNavItem>

            <NavExpander v-if="currentUser.has.signal" :is-expanded="isExpanded">
                <template #default="{ toggleChildren, isActive, setActive }">
                    <LeftNavItem
                        :item="{
                            title: 'Signal',
                            to: '/signal',
                        }"
                        :is-expanded="isExpanded"
                        @active-route-change="setActive($event)"
                    >
                        <SignalIcon />
                        <template #expander>
                            <ButtonTriangle class="ml-2" :is-active="isActive" @click="toggleChildren" />
                        </template>
                    </LeftNavItem>
                </template>
                <template #children>
                    <ul>
                        <LeftNavItemChild
                            :item="{
                                title: 'Search',
                                to: {
                                    name: 'signal-search',
                                },
                            }"
                            :is-expanded="isExpanded"
                        />

                        <LeftNavItemChild
                            :item="{
                                title: 'Market Benchmarks',
                                to: {
                                    name: 'signal-market-benchmarks',
                                },
                            }"
                            :is-expanded="isExpanded"
                        />

                        <LeftNavItemChild
                            :item="{
                                title: 'Peer Benchmarks',
                                to: {
                                    name: 'signal-peer-benchmarks',
                                },
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>
                </template>
            </NavExpander>

            <LeftNavItem
                v-if="currentUser.has.amplitude"
                :item="{
                    title: 'Amplitude',
                    to: '/amplitude',
                }"
                :is-expanded="isExpanded"
            >
                <AmplitudeLogoIcon />
            </LeftNavItem>

            <LeftNavItem
                :item="{
                    title: 'Market Share',
                    to: '/market-share',
                }"
                :is-expanded="isExpanded"
            >
                <ChartPieIcon />
            </LeftNavItem>

            <NavExpander v-if="adminUser || currentUser.isDasboardsMenuConfigured" :is-expanded="isExpanded">
                <template #default="{ toggleChildren, isActive, setActive }">
                    <LeftNavItem
                        :item="{
                            title: 'Dashboards',
                            to: `/dashboards`,
                        }"
                        :is-expanded="isExpanded"
                        @active-route-change="setActive($event)"
                    >
                        <ChartBarSquareIcon />
                        <template #expander>
                            <ButtonTriangle class="ml-2" :is-active="isActive" @click="toggleChildren" />
                        </template>
                    </LeftNavItem>
                </template>
                <template #children>
                    <ul v-if="adminUser || currentUser.dashboard_allowed === 'radiology'">
                        <LeftNavItemChild
                            :item="{
                                title: 'Radiology',
                                to: '/dashboards/radiology',
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>

                    <ul v-if="adminUser || currentUser.isDashboardConfigured('behavioral-next')">
                        <LeftNavItemChild
                            :item="{
                                title: 'Behavioral Health Next',
                                to: '/dashboards/behavioral-next',
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>

                    <ul v-if="adminUser || currentUser.isDashboardConfigured('behavioral-compare')">
                        <LeftNavItemChild
                            :item="{
                                title: 'Behavioral Health Compare',
                                to: '/dashboards/behavioral-compare',
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>
                </template>
            </NavExpander>

            <LeftNavItem
                :item="{
                    title: 'Available Networks',
                    to: {
                        path: '/networks',
                        query: null,
                    },
                }"
                :is-expanded="isExpanded"
            >
                <ShieldCheckIcon />
            </LeftNavItem>

            <LeftNavItemButton
                v-if="adminUser"
                :item="{
                    title: 'Provider Search',
                    to: '/provider-search',
                }"
                :is-expanded="isExpanded"
                @click="openProviderDirectory"
            >
                <DocumentMagnifyingGlassIcon />
            </LeftNavItemButton>

            <div class="flex-auto basis-full" />
            <div v-if="adminUser" class="flex flex-col gap-3 border-y border-solid border-brand-blue/20 py-6">
                <NavExpander :is-expanded="isExpanded">
                    <template #default="{ toggleChildren, isActive, setActive }">
                        <LeftNavItem
                            :item="{
                                title: 'MRF Data Sources',
                                to: '/sources',
                            }"
                            :is-expanded="isExpanded"
                            @active-route-change="setActive($event)"
                        >
                            <ServerStackIcon />
                            <template #expander>
                                <ButtonTriangle class="ml-2" :is-active="isActive" @click="toggleChildren" />
                            </template>
                        </LeftNavItem>
                    </template>
                    <template #children>
                        <ul>
                            <LeftNavItemChild
                                :item="{
                                    title: 'Ingest Report',
                                    to: '/sources/ingest-report',
                                }"
                                :is-expanded="isExpanded"
                            />
                            <ul class="-mt-1 ml-1">
                                <LeftNavItemTertiary
                                    :item="{
                                        title: 'Payers',
                                        to: '/sources/ingest-report/payers',
                                    }"
                                    :is-expanded="isExpanded"
                                />

                                <LeftNavItemTertiary
                                    :item="{
                                        title: 'Health Systems',
                                        to: '/sources/ingest-report/health-systems?isAuto=true&status=ERROR',
                                    }"
                                    :is-expanded="isExpanded"
                                />
                            </ul>

                            <LeftNavItemChild
                                :item="{
                                    title: 'Audits',
                                    to: {
                                        path: '/sources/audits',
                                        query: null,
                                    },
                                }"
                                :is-expanded="isExpanded"
                            />
                            <ul class="-mt-1 ml-1">
                                <LeftNavItemTertiary
                                    :item="{
                                        title: 'Networks',
                                        to: '/sources/audits/networks',
                                    }"
                                    :is-expanded="isExpanded"
                                />
                                <LeftNavItemTertiary
                                    :item="{
                                        title: 'Locations',
                                        to: '/sources/audits/locations',
                                    }"
                                    :is-expanded="isExpanded"
                                />
                            </ul>

                            <LeftNavItemChild
                                :item="{
                                    title: 'Payors',
                                    to: '/sources/payors',
                                }"
                                :is-expanded="isExpanded"
                            />

                            <LeftNavItemChild
                                :item="{
                                    title: 'Providers',
                                    to: '/sources/providers',
                                }"
                                :is-expanded="isExpanded"
                            />

                            <LeftNavItemChild
                                :item="{
                                    title: 'Health Systems',
                                    to: '/sources/health-systems',
                                }"
                                :is-expanded="isExpanded"
                            />
                            <!-- <LeftNavItemChild
                                :item="{
                                    title: 'TPAs',
                                    to: '/sources/tpas',
                                }"
                                :is-expanded="isExpanded"
                            /> -->
                        </ul>
                    </template>
                </NavExpander>

                <LeftNavItem
                    v-if="adminUser"
                    :item="{
                        title: 'Accounts',
                        to: '/accounts',
                    }"
                    :is-expanded="isExpanded"
                >
                    <UsersIcon />
                </LeftNavItem>
            </div>

            <LeftNavItemButtonReversed
                v-if="isReady"
                :item="{
                    title: 'Help',
                    to: '/help',
                }"
                :is-expanded="isExpanded"
                @click="toggle"
            >
                <XCircleIcon v-if="isOpen" />
                <QuestionMarkCircleIcon v-else />
            </LeftNavItemButtonReversed>
            <LeftNavItem
                :item="{
                    title: currentUser.email,
                    to: '/sign-out',
                }"
                :is-expanded="isExpanded"
                class="tooltip"
            >
                <ArrowRightOnRectangleIcon />
                <span class="tooltiptext">Sign Out</span>
            </LeftNavItem>
        </div>
    </nav>
</template>

<script setup>
import { ref } from 'vue'
import {
    UsersIcon,
    ChartBarSquareIcon,
    ArrowRightOnRectangleIcon,
    ChevronRightIcon,
    ServerStackIcon,
    ShieldCheckIcon,
    ChartPieIcon,
    HomeIcon,
    DocumentMagnifyingGlassIcon,
    SignalIcon,
    QuestionMarkCircleIcon,
    XCircleIcon,
} from '@heroicons/vue/24/outline'

import ButtonTriangle from '@/components/ButtonTriangle.vue'
import NavExpander from '@/components/NavExpander.vue'
import LeftNavLogo from '@/components/LeftNavLogo.vue'
import LeftNavItem from '@/components/LeftNavItem.vue'
import LeftNavItemButton from '@/components/LeftNavItemButton.vue'
import LeftNavItemButtonReversed from '@/components/LeftNavItemButtonReversed.vue'
import LeftNavItemChild from '@/components/LeftNavItemChild.vue'
import { useAnalytics } from '@/composables/analytics.js'
import { useCurrentUserStore } from '@/stores/currentUser'
import { useModalProviderSearch } from '@/composables/modalProviderSearch'
import { useBeacon } from '@/composables/beacon'

import AmplitudeLogoIcon from '@/assets/svg/amplitude-logo.svg'

const currentUser = useCurrentUserStore()
const modalProviderSearch = useModalProviderSearch()
const { analytics } = useAnalytics()
const { isReady, isOpen, toggle } = useBeacon()

const adminUser = currentUser.isAdmin
const isExpanded = ref(false)

function setExpanded(newState) {
    isExpanded.value = newState
}

function openProviderDirectory() {
    modalProviderSearch.launch()

    analytics.track({
        event: 'Provider Directory Opened From Nav',
    })
}
</script>

<style scoped>
.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;

    /* Position the tooltip */
    position: absolute;
    bottom: 120%;
    left: 0%;
    margin-right: -15px;
    z-index: 1;
}

.tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 100%;
    /* At the top of the tooltip */
    right: 74%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>
