import { createApp } from 'vue'
import App from './App.vue'
import { routes } from './routes.js'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import qs from 'qs'
import FloatingVue from 'floating-vue'
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'
import { VueQueryPlugin } from '@tanstack/vue-query'

import {
    RiBillLine,
    RiMapPinAddLine,
    RiCoinsLine,
    RiFilterLine,
    RiStethoscopeLine,
    RiMap2Line,
    RiFirstAidKitLine,
    RiFilter3Line,
    RiAdvertisementLine,
} from 'oh-vue-icons/icons'

import 'simplebar-vue/dist/simplebar.min.css'
import 'floating-vue/dist/style.css'

import './tailwind.css'

async function startApp() {
    /**
     * Init Meticulous
     */
    // Record all sessions on localhost, staging stacks and preview URLs
    if (!isProduction()) {
        // Start the Meticulous recorder before you initialise your app.
        // Note: all errors are caught and logged, so no need to surround with try/catch
        await tryLoadAndStartRecorder({
            recordingToken: 'LCEYKJaQobzrLTzvb84thJy1BvGa3Je1ZoJp31GX',
            isProduction: false,
        })

        console.info('Meticulous Recording initialized')
    }

    const app = createApp(App)

    const router = createRouter({
        history: createWebHistory(),
        routes,
        parseQuery: (q) => qs.parse(q, { arrayLimit: 15 }),
        stringifyQuery: (q) => qs.stringify(q, { encodeValuesOnly: true, indices: true }),
    })

    app.component('VIcon', OhVueIcon)

    addIcons(
        RiBillLine,
        RiMapPinAddLine,
        RiCoinsLine,
        RiFilterLine,
        RiStethoscopeLine,
        RiMap2Line,
        RiFirstAidKitLine,
        RiFilter3Line,
        RiAdvertisementLine
    )

    if (import.meta.env.PROD) {
        Sentry.init({
            app,
            dsn: 'https://2ef7607277fb4bc72cc70c42ada7f6c0@o4505630155407360.ingest.sentry.io/4505630158356480',
            environment: import.meta.env.MODE,
            integrations: [Sentry.browserTracingIntegration({ router })],

            // Performance Monitoring
            tracesSampleRate: 0.15, // Capture 50% of the transactions
        })
    }

    app.use(router)
        .use(createPinia())
        .use(VueQueryPlugin)
        .use(FloatingVue, {
            themes: {
                'signal-tooltip': {
                    $extend: 'tooltip',
                    // Other options (see the 'Global options' section)
                },
            },
        })
        .mount('#app')
}

function isProduction() {
    return window.location.hostname.indexOf('serifhealth.com') > -1
}

startApp()

console.info(`Loading Serif Health app, ${import.meta.env.VITE_APP_ENV} environment.`)
