<template>
    <div class="min-h-screen">
        <slot v-if="currentUser.id && !isAuthenticating" />
        <div class="pointer-events-none absolute inset-0 z-10 flex h-screen items-center justify-center">
            <div
                :class="{
                    '!opacity-0 transition delay-200 duration-700': currentUser.id && !isAuthenticating,
                }"
                class="h-48 w-48 text-brand-purple opacity-60"
            >
                <SerifLogoMark class="scale-up-center" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCurrentUserStore } from '@/stores/currentUser'
import { onMounted } from 'vue'

import SerifLogoMark from '@/assets/svg/serif-logo-mark.svg'

const isAuthenticating = ref(true)
const router = useRouter()
const currentUser = useCurrentUserStore()

async function authenticate() {
    isAuthenticating.value = true
    currentUser
        .authenticate()
        .then(async () => {
            await currentUser.fetchAccountDetails()
        })
        .catch(async (err) => {
            // Forward to Confirmation Screen if user record is not confirmed
            if (err.message === 'Forbidden: Not Confirmed') {
                if (currentUser.email) {
                    await currentUser.cognito.resendConfirmationCode({
                        email: currentUser.email,
                    })

                    return router.push(
                        `/user-confirmation?${new URLSearchParams({
                            email: currentUser.email,
                        })}`
                    )
                }

                return router.push('/no-access')
            }

            return router.push('/no-access')
        })
        .finally(() => {
            if (currentUser.isApisProduct) {
                router.push('/docs')
                return
            }

            isAuthenticating.value = false
        })
}

onMounted(async () => {
    await authenticate()
})

watch()
</script>

<style lang="css" scoped>
.scale-up-center {
    -webkit-animation: scale-up-center 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-27 13:11:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
</style>
